import { Injectable } from '@angular/core';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const COMPANY_KEY = 'auth-company';
const ROW_RECONNAISSANCE = 'auth-row';
const ROW_RECONNAISSANCE_Name = 'auth-namerow';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {
  constructor() { }

  signOut(): void {
    window.sessionStorage.clear();
  }

  public saveToken(token: string): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.sessionStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.sessionStorage.removeItem(USER_KEY);
    window.sessionStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.sessionStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }


  public saveRow(company: any): void {
    window.sessionStorage.removeItem(ROW_RECONNAISSANCE);
    window.sessionStorage.setItem(ROW_RECONNAISSANCE, JSON.stringify(company));
  }
  public getRow(): any {
    const company = window.sessionStorage.getItem(ROW_RECONNAISSANCE);
    if (company) {
      return JSON.parse(company);
    }
    return {};
  }
  public saveRowName(company: any): void {
    window.sessionStorage.removeItem(ROW_RECONNAISSANCE_Name);
    window.sessionStorage.setItem(ROW_RECONNAISSANCE_Name, JSON.stringify(company));
  }
  public getRowName(): any {
    const company = window.sessionStorage.getItem(ROW_RECONNAISSANCE_Name);
    if (company) {
      return JSON.parse(company);
    }
    return {};
  }

  public saveCompany(company: any): void {
    window.sessionStorage.removeItem(COMPANY_KEY);
    window.sessionStorage.setItem(COMPANY_KEY, JSON.stringify(company));
  }
  public getCompany(): any {
    const company = window.sessionStorage.getItem(COMPANY_KEY);
    if (company) {
      return JSON.parse(company);
    }
    return {};
  }

}
