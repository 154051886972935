<nav class="navbar">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)">
    <i class="feather icon-menu"></i>
  </a>
  <div class="navbar-content">
    <form class="search-form">
      <div class="input-group">
        <div class="input-group-text">
          <i class="feather icon-search"></i>
        </div>
        <input type="text" class="form-control" id="navbarForm" placeholder="Cherche ici...">
      </div>
    </form>
    <ul class="navbar-nav">

      <li class="nav-item nav-apps" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="appsDropdown">
          <i class="link-icon feather icon-grid"></i>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="appsDropdown">
          <div class="px-3 py-2 d-flex align-items-center justify-content-between border-bottom">
            <p class="mb-0 fw-bold">TRUE DELTAT </p>

          </div>
          <div class="row g-0 p-1">
            <div class="col-3 text-center">
              <a routerLink="/apps/chat" class="dropdown-item d-flex flex-column align-items-center justify-content-center
               wd-70 ht-70"><i class="feather icon-message-square icon-lg mb-1"></i><p class="tx-12">Chat</p></a>
            </div>
            <div class="col-3 text-center">
              <a routerLink="/apps/calendar" class="dropdown-item d-flex flex-column align-items-center justify-content-center
              wd-70 ht-70"><i class="feather icon-calendar icon-lg mb-1"></i><p class="tx-12">Calendrier</p></a>
            </div>
            <div class="col-3 text-center">
              <a routerLink="/apps/taskborders" class="dropdown-item d-flex flex-column align-items-center
              justify-content-center wd-70 ht-70"><i class="feather icon-trello icon-lg mb-1"></i><p class="tx-12">Tâches</p></a>
            </div>
            <div class="col-3 text-center">
              <a routerLink="/general/profile" class="dropdown-item d-flex flex-column align-items-center
               justify-content-center wd-70 ht-70"><i class="feather icon-instagram icon-lg mb-1"></i><p class="tx-12">Profil</p></a>
            </div>
          </div>

        </div>
      </li>

      <li class="nav-item nav-notifications" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="notificationDropdown">
          <i class="link-icon feather icon-bell"></i>
          <div class="indicator">
            <div class="circle"></div>
          </div>
        </a>
        <div ngbDropdownMenu [perfectScrollbar] class="px-0" aria-labelledby="notificationDropdown">


        </div>
      </li>
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <div  class="rounded-circle" style="width: 37px; height: 37px; background-color: #75bbe7; display: flex; align-items: center; justify-content: center; margin-top: 10px;">

            <span class="img-xs rounded-circle" style="color: white; font-size: 20px;">&nbsp;{{currentUser.firstName.fr.substring(0,2)}}</span>
          </div>
        </a>
        <div ngbDropdownMenu class="px-0" aria-labelledby="profileDropdown">
          <div class="d-flex flex-column align-items-center border-bottom px-5 py-3">
            <div class="mb-3">
              <div  class="rounded-circle" style="width: 37px; height: 37px; background-color: #75bbe7; display: flex; align-items: center; justify-content: center; margin-top: 10px;">
                <span class="img-xs rounded-circle" style="color: white; font-size: 20px;">&nbsp;{{currentUser.lastName.fr.substring(0,2)}}</span>
              </div>                </div>
            <div class="text-center">
              <p class="tx-16 fw-bolder">{{currentUser.login}}</p>
              <p class="tx-12 text-muted">{{currentUser.email}}</p>
            </div>
          </div>
          <ul class="list-unstyled p-1">
            <li class="dropdown-item py-2">
              <a routerLink="/general/profile" class="d-flex text-body ms-0">
                <i class="feather icon-user me-2 icon-md"></i>
                <span>Profil</span>
              </a>
            </li> 
            <li class="dropdown-item py-2">
              <a href="" (click)="onLogout($event)" class="d-flex text-body ms-0">
                <i class="feather icon-log-out me-2 icon-md"></i>
                <span>Se déconnecter</span>
              </a>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</nav>
