import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject} from '@angular/core';
import {DOCUMENT} from '@angular/common';

import MetisMenu from 'metismenujs';

import {MENUOHARM} from './menu';
import {MENU} from './menu';
import {MENUOHARMCH} from './menu';
import {MenuItem} from './menu.model';
import {Router, NavigationEnd, Routes, ActivatedRoute} from '@angular/router';
import {TokenStorageService} from "../../../core/guard/token-storage.service";


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;

  menuItems: MenuItem[] = [];
  modules: any = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;

  constructor(@Inject(DOCUMENT) private document: Document, private renderer: Renderer2, private router: Router,
              private tokenStorageService: TokenStorageService, private route: ActivatedRoute) {


    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */

      }
    });

  }

  ngOnInit(): void {

    let m: MenuItem;
    if (this.tokenStorageService.getUser().roles[0] === 'ROLE_PHARMACIE') {
       this.menuItems = MENUOHARM;
    }
    else
      if (this.tokenStorageService.getUser().roles[0] === 'ROLE_PHARMACIE_CHEQUE') {
        this.menuItems = MENUOHARMCH;
      }
      else {
        this.menuItems = MENU;
      }
      let s: MenuItem;

      this.modules = this.tokenStorageService.getCompany().modules;

      //this.menuItems = MENU;


      /**
       * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
       */
      const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
      desktopMedium.addEventListener('change', () => {
        this.iconSidebar;
      });
      this.iconSidebar(desktopMedium);


    }

    ngAfterViewInit()
    {
      // activate menu item
      new MetisMenu(this.sidebarMenu.nativeElement);

      this._activateMenuDropdown();


    }

    /**
     * Toggle sidebar on hamburger button click
     */
    toggleSidebar(e
  :
    Event
  )
    {
      this.sidebarToggler.nativeElement.classList.toggle('active');
      this.sidebarToggler.nativeElement.classList.toggle('not-active');

      if (window.matchMedia('(min-width: 992px)').matches) {
        e.preventDefault();
        this.document.body.classList.toggle('sidebar-folded');
      } else if (window.matchMedia('(max-width: 991px)').matches) {
        e.preventDefault();
        this.document.body.classList.toggle('sidebar-open');
      }
    }


    /**
     * Toggle settings-sidebar
     */
    toggleSettingsSidebar(e
  :
    Event
  )
    {
      e.preventDefault();
      this.document.body.classList.toggle('settings-open');
    }


    /**
     * Open sidebar when hover (in folded folded state)
     */
    operSidebarFolded()
    {
      if (this.document.body.classList.contains('sidebar-folded')) {
        this.document.body.classList.add("open-sidebar-folded");
      }
    }


    /**
     * Fold sidebar after mouse leave (in folded state)
     */
    closeSidebarFolded()
    {
      if (this.document.body.classList.contains('sidebar-folded')) {
        this.document.body.classList.remove("open-sidebar-folded");
      }
    }

    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    iconSidebar(mq
  :
    MediaQueryList
  )
    {
      if (mq.matches) {
        this.document.body.classList.add('sidebar-folded');
      } else {
        this.document.body.classList.remove('sidebar-folded');
      }
    }


    /**
     * Switching sidebar light/dark
     */
    onSidebarThemeChange(event
  :
    Event
  )
    {
      this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
      this.document.body.classList.add((<HTMLInputElement>event.target).value);
      this.document.body.classList.remove('settings-open');
    }


    /**
     * Returns true or false if given menu item has child or not
     * @param item menuItem
     */
    hasItems(item
  :
    MenuItem
  )
    {
      return item.subItems !== undefined ? item.subItems.length > 0 : false;
    }


    /**
     * Reset the menus then hilight current active menu item
     */
    _activateMenuDropdown()
    {
      this.resetMenuItems();
      this.activateMenuItems();
      if ((this.router.url.indexOf('/reconciliation/detail-reconciliation/') !== -1) || (this.router.url.indexOf('/reconaissance/detailReconaissance/') !== -1)) {

        this.document.body.classList.toggle('sidebar-folded');

      } else {
        this.document.body.classList.toggle('sidebar-open');

      }
    }


    /**
     * Resets the menus
     */
    resetMenuItems()
    {

      const links = document.getElementsByClassName('nav-link-ref');

      for (let i = 0; i < links.length; i++) {
        const menuItemEl = links[i];
        menuItemEl.classList.remove('mm-active');
        const parentEl = menuItemEl.parentElement;

        if (parentEl) {
          parentEl.classList.remove('mm-active');
          const parent2El = parentEl.parentElement;

          if (parent2El) {
            parent2El.classList.remove('mm-show');
          }

          const parent3El = parent2El?.parentElement;
          if (parent3El) {
            parent3El.classList.remove('mm-active');

            if (parent3El.classList.contains('side-nav-item')) {
              const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

              if (firstAnchor) {
                firstAnchor.classList.remove('mm-active');
              }
            }

            const parent4El = parent3El.parentElement;
            if (parent4El) {
              parent4El.classList.remove('mm-show');

              const parent5El = parent4El.parentElement;
              if (parent5El) {
                parent5El.classList.remove('mm-active');
              }
            }
          }
        }
      }
    }
    ;


    /**
     * Toggles the menu items
     */
    activateMenuItems()
    {

      const links: any = document.getElementsByClassName('nav-link-ref');

      let menuItemEl = null;

      for (let i = 0; i < links.length; i++) {
        // tslint:disable-next-line: no-string-literal
        if (window.location.pathname === links[i]['pathname']) {

          menuItemEl = links[i];

          break;
        }
      }

      if (menuItemEl) {
        menuItemEl.classList.add('mm-active');
        const parentEl = menuItemEl.parentElement;

        if (parentEl) {
          parentEl.classList.add('mm-active');

          const parent2El = parentEl.parentElement;
          if (parent2El) {
            parent2El.classList.add('mm-show');
          }

          const parent3El = parent2El.parentElement;
          if (parent3El) {
            parent3El.classList.add('mm-active');

            if (parent3El.classList.contains('side-nav-item')) {
              const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

              if (firstAnchor) {
                firstAnchor.classList.add('mm-active');
              }
            }

            const parent4El = parent3El.parentElement;
            if (parent4El) {
              parent4El.classList.add('mm-show');

              const parent5El = parent4El.parentElement;
              if (parent5El) {
                parent5El.classList.add('mm-active');
              }
            }
          }
        }
      }
    }
    ;


  }
