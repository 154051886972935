import {MenuItem} from './menu.model';

export const MENU: MenuItem[] = [


  {
    label: 'Lecture des documents',
    isTitle: true
  },
  {
    label: 'Tableau de bord',
    icon: 'home',
    link: '/dashboard/recongnition',

  },
  {
    label: 'Extraction des données',
    icon: 'file',
    link: '/reconaissance/listReconaissances',

  },
  {
    label: 'Mes Modèles',
    icon: 'image',
    link: '/models/listCategories',

  },
  {
    label: 'Paramétrage',
    isTitle: true
  },
  {
    label: 'Templates Bordereaux',
    icon: 'file',
    link: '/bordereaux/list',

  },
  {
    label: 'Relevé Manuel',
    icon: 'file',
    link: '/virmentespeces/list',

  },
  {
    label: 'Mes Données',
    icon: 'file',
    link: '/mes-donnees/list',

  },
  {
    label: 'GESTION DE DONNÉES',
    isTitle: true
  },
  {
    label: 'Tableau de bord',
    icon: 'home',
    link: '/dashboard/reconciliation',

  },


  {
    label: 'Rapprochement des données',
    icon: 'file-text',
    link: '/reconciliation/list-reconciliation',

  },
  {
    label: 'Conversion',
    icon: 'copy',
    link: '/convertion/addconvertion',
  },


  {
    label: 'TRUE DELTAT',
    isTitle: true
  },

  {
    label: 'Utilisateurs',
    icon: 'users',
    link: '/users/list-user',
  },
  {
    label: 'Ajouter un utilisateur',
    icon: 'user-check',
    link: '/users/add-user',
  },


  {
    label: 'Chat',
    icon: 'message-square',
    link: '/apps/chat',
  },
  {
    label: 'Planificateur de tâches',
    icon: 'trello',
    link: '/apps/taskborders',
    badge: {
      variant: 'primary',
    }
  },
  {
    label: 'Calendrier',
    icon: 'calendar',
    link: '/apps/calendar',
    badge: {
      variant: 'primary',
      text: 'Event',
    }
  }
];
export const MENUOHARM: MenuItem[] = [

  {
    label: 'Lecture des documents',
    isTitle: true
  },
  {
    label: 'Tableau de bord',
    icon: 'home',
    link: '/dashboard/recongnition',

  },
  {
    label: 'Extraction des données',
    icon: 'file',
    link: '/reconaissance/listReconaissances',

  },


  {
    label: 'Mes Modèles',
    icon: 'image',
    link: '/models/listCategories',

  },
  {
    label: 'Paramétrage',
    isTitle: true
  },
  {
    label: 'Templates Bordereaux',
    icon: 'file',
    link: '/bordereaux/list',

  },


  {
    label: 'GESTION DE DONNÉES',
    isTitle: true
  },
  {
    label: 'Tableau de bord',
    icon: 'home',
    link: '/dashboard/reconciliation',

  },


  {
    label: 'Rapprochement des données',
    icon: 'file-text',
    link: '/reconciliation/list-reconciliation',

  },



];
export const MENUOHARMCH: MenuItem[] = [

  {
    label: 'Lecture des documents',
    isTitle: true
  },
  {
    label: 'Tableau de bord',
    icon: 'home',
    link: '/dashboard/recongnition',

  },
  {
    label: 'Extraction des données',
    icon: 'file',
    link: '/reconaissance/listReconaissances',

  },


  {
    label: 'Mes Modèles',
    icon: 'image',
    link: '/models/listCategories',

  },
  {
    label: 'Paramétrage',
    isTitle: true
  },
  {
    label: 'Templates Bordereaux',
    icon: 'file',
    link: '/bordereaux/list',

  },

  
  {
    label: 'Mes Données',
    icon: 'file',
    link: '/mes-donnees/list',

  },

];
