import { Component, OnInit, ViewChild, ElementRef, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import {TokenStorageService} from "../../../core/guard/token-storage.service";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  currentUser:any;
  unreadNotificationsCount:any;
  constructor(private tokenStorageService: TokenStorageService,
              @Inject(DOCUMENT) private document: Document,
              private renderer: Renderer2,
              private router: Router
  ) { }

  ngOnInit(): void {
    this.currentUser = this.tokenStorageService.getUser();


  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e: Event) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  /**
   * Logout
   */
  onLogout(e: Event) {
    e.preventDefault();
    localStorage.removeItem('isLoggedin');

    if (!localStorage.getItem('isLoggedin')) {
      this.router.navigate(['/auth/login']);
    }
  }
  updateIndicator(): void {
    // Get a reference to the indicator element
    const indicator = document.querySelector('.indicator');
    if (indicator) {
      if (this.unreadNotificationsCount!=0) {
        indicator.classList.add('active');
      } else {
        indicator.classList.remove('active');
      }
    }
  }

}
