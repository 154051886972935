import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './views/layout/base/base.component';
import { AuthGuard } from './core/guard/auth.guard';
import { ErrorPageComponent } from './views/pages/error-page/error-page.component';


const routes: Routes = [
  { path:'auth', loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'apps',
        loadChildren: () => import('./views/pages/apps/apps.module').then(m => m.AppsModule)
      },
      {
        path: 'users',
        loadChildren: () => import('./views/pages/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'reconciliation',
        loadChildren: () => import('./views/pages/reconciliation/reconciliation.module').then(m => m.ReconciliationModule)
      },
      {
        path: 'general',
        loadChildren: () => import('./views/pages/general/general.module').then(m => m.GeneralModule)
      },

      {
        path: 'models',
        loadChildren: () => import('./views/pages/models/models.module').then(m => m.ModelsModule)
      },
      {
        path: 'reconaissance',
        loadChildren: () => import('./views/pages/reconnaisance/reconnaisance.module').then(m => m.ReconnaisanceModule)
      },
      {
        path: 'virmentespeces',
        loadChildren: () => import('./views/pages/virmentespeces/virmentespeces.module').then(m => m.VirmentespecesModule)
      },
      {
        path: 'bordereaux',
        loadChildren: () => import('./views/pages/bordereaux/bordereaux.module').then(m => m.BordereauxModule)
      },
      {
        path: 'mes-donnees',
        loadChildren: () => import('./views/pages/mesdonnes/mesdonnes.module').then(m => m.MesdonnesModule)
      },
      {
        path: 'convertion',
        loadChildren: () => import('./views/pages/convertion/convertion.module').then(m => m.ConvertionModule)
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },

      // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    ]
  },
  {
    path: 'error',
    component: ErrorPageComponent,
    data: {
      'type': 404,
      'title': 'Page Not Found',
      'desc': 'Oopps!! The page you were looking for doesn\'t exist.'
    }
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent
  },
  { path: '**', redirectTo: 'error', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top' })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
